<template>
  
  <div class="container-fluid">
    <div class="video-container" :style="videoContainerStyles">
      <video ref="videoElement" muted playsinline disablepictureinpicture poster="../image/beijing.jpg"
        src="../image/video.mp4" class="video-element"></video>
      <div>00000</div>
    </div>

    <div v-if="showSection">
      <transition name="slide-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="currentSection === 0" class="section position-relative">
          <div class="text-content">
            <li><a class="sectionone">EarthSDK 开源免费地球可视化二次开发框架</a></li>
            <li><a class="sectiontwo">一套代码，实现Cesium和UE「双引擎」可视化</a></li>
          </div>

        </div>
      </transition>

      <transition name="slide-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="currentSection === 1" class="section">
          <div class="text-content">
            <li><a class="sectiontwo">JS与UE的无缝通信，实现前端程序员也可以开发UE4/5数字孪生</a></li>
            <li><a class="sectiontwo">支持物联网数据，支持地理信息系统(GIS)集成</a></li>
          </div>
        </div>
      </transition>
      <transition name="fade-slide-up">
        <div v-if="currentSection === 2" class="section">
          <div class="text-content">
            <li><a class="sectiontwo">免费·开源，项目安全稳定有保障</a></li>
            <li><a class="sectiontwo">简单·易用，内置上百种常用功能，一行代码轻松创建</a></li>
          </div>
        </div>
      </transition>
      <transition name="">
        <div v-if="currentSection === 3" class="section"></div>
      </transition>
    </div>

    <div v-if="showDragHint" class="drag-hint">▼</div>
    <div class=" text-center mt-5 text-dibu">
      <p class="mb-3 sectionthree pb-5">官网系列课程免费学，从入门到精通</p>
      <p class="mb-3 sectionthree pb-5">———— 有文档 | 有视频 | 有示例代码 ————</p>
      <div class="row justify-content-center pt-3">
        <div class="col-auto">
          <button type="button" @click="shilibtn" class="btn btn-primary btn-lg">功能示例</button>
        </div>
        <div class="col-auto">
          <button type="button" @click="chuxuezhebtn" class="btn btn-success btn-lg">初学者教程</button>
        </div>
        <div class="col-auto">
          <button type="button" @click="apibtn" class="btn btn-success btn-lg">API文档</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';

const videoElement = ref(null);
const scrollProgress = ref(0);
const isAtBottom = ref(false); // 标记是否在底部
const currentSection = ref(0); // 新增：当前激活的section
const showDragHint = ref(true); // 控制提示显示的响应式引用
const showSection = ref(true); // 控制提示显示的响应式引用

const windowHeight = window.innerHeight;

const apibtn = () => {
  window.open("https://c0yh9tnn0na.feishu.cn/drive/folder/SP0AfHYRhlUJP0d370AcagucnSf?from=from_copylink");
}

const chuxuezhebtn = () => {
  window.open("https://wzonfjgpzd.feishu.cn/docx/NUssdX48WobIqlxU9dFcaRDhnwc");
}
const shilibtn = () => {
  window.open("./example/index.html");
}

const updateScrollProgress = () => {

  const scrollY = window.scrollY;
  const scrollPosition = 2. * windowHeight;
  const playPosition = 100; // 定义开始播放的滚动位置
  const blurStart = 1.5 * windowHeight; // 开始应用模糊效果的滚动位置
  const blurEnd = 2.0 * windowHeight; // 停止应用模糊效果的滚动位置

  //影响视频的模糊效果，渐变作用。
  if (scrollY > blurStart && scrollY < blurEnd) {
    const progress = (scrollY - blurStart) / (blurEnd - blurStart);
    scrollProgress.value = progress;
  } else if (scrollY >= blurEnd) {
    scrollProgress.value = 1;
  } else {
    scrollProgress.value = 0;
  }


  if (scrollY > scrollPosition) {
    isAtBottom.value = true;
  } else {
    isAtBottom.value = false;
  }

  //如果超过playPosition像素。就开始播放，已经少于这个像素就重新未播放
  if (scrollY > playPosition && videoElement.value.paused) {
    videoElement.value.play();
    console.log("play");
  } else if (scrollY <= playPosition) {
    videoElement.value.currentTime = 0;
    videoElement.value.pause();
    videoElement.value.load();

  }


  // 根据滚动位置更新currentSection的值
  if (scrollY < 0.1 * windowHeight) {
    currentSection.value = 0;
    showSection.value = true;
  } else if (scrollY >= 0.1 * windowHeight && scrollY < 1.0 * windowHeight) {
    currentSection.value = 1;
    showSection.value = true;
  } else if (scrollY >= 1.0 * windowHeight && scrollY < 2.0 * windowHeight) {
    currentSection.value = 2;
    showSection.value = true;
  } else if (scrollY >= 2.0 * windowHeight) {
    currentSection.value = 3;
    showSection.value = false;
  }

  if (scrollY > blurEnd) {
    showDragHint.value = false;
  } else {
    showDragHint.value = true;
  }
};

const videoContainerStyles = computed(() => {
  if (isAtBottom.value) {
    // 当滚动到指定位置，使视频容器停靠在底部
    return {
      position: 'absolute', // 从fixed转变为absolute
      bottom: '0px',
      width: '100%', // 保持宽度与父容器一致
      height: '100vh', // 修正为100vh或其他固定值
      transform: 'scale(0.7) translateZ(0px)', // 清除可能之前应用的变换效果
      opacity: '0.8', // 重置透明度为完全不透明
      filter: 'blur(10px)', // 清除可能之前应用的模糊效果
      zIndex: '1',
    };
  } else if (scrollProgress.value > 0) {
    // 应用滚动进度相关的样式变化
    return {
      position: 'fixed', // 在未达到底部之前保持fixed
      filter: `blur(${scrollProgress.value * 10}px)`,
      transform: `scale(${1 - scrollProgress.value * 0.3}) translateZ(0)`,
      opacity: `${1 - scrollProgress.value * 0.2}`,
      zIndex: '1',
    };
  }
  return {
    position: 'fixed', // 默认样式保持fixed
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
  };
});


onMounted(() => {
  window.addEventListener('scroll', updateScrollProgress);
  updateScrollProgress(); // 初始化时检查滚动位置
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateScrollProgress);
});

</script>

<style scoped>
.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.we-chat {
  width: 3%;
  height: 6%;
  z-index: 999;
  right: 14px;
  bottom: 10%;
}

.we-chat-code {
  width: 50%;

}

.container-fluid {

  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 300vh;
  /* color: #000; */
  margin: 0px;
  padding: 0px;
}

.video-container {
  position: fixed;
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
  color: #fff;
  text-align: center;
  list-style-type: none;
  padding-bottom: 50px;
}

.sectionone {
  font-size: 66px;
  font-weight: bold;
  word-break: break-word;
  text-decoration: none;
  color: inherit;
  font-weight: 100px !important;
}

.sectiontwo {
  font-size: 48px;
  word-break: break-word;
  text-decoration: none;
  color: inherit;
  font-weight: 300 !important;
  text-shadow: 1px 1px 2px rgb(63, 62, 62);
  line-height: 30px;
}

@keyframes slide-up {
  from {
    transform: translateY(200px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-200px);
    opacity: 0;
  }
}

.slide-up-enter-active {
  animation: slide-up 1s ease-out forwards;
}

.slide-up-leave-active {
  animation: slide-up-leave 0.5s ease-out forwards;
}

.drag-hint {
  position: fixed;
  bottom: 150px;
  /* 距离底部一定距离 */
  left: 50%;
  transform: translateX(-50%);
  /* 水平居中 */
  padding: 5px 12px;
  background-color: rgba(139, 134, 134, 0.397);
  color: rgb(255, 255, 255);
  border-radius: 50px;
  font-size: 24px;
  z-index: 10;
  /* 确保在最上层 */
  transition: opacity 0.5s ease;
}

.text-dibu {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 360px;
  margin: 0px;
  padding: 0px !important;
  z-index: 100;
  color: #fff;
}

.sectionthree {
  font-size: 36px;
  word-break: break-word;
  text-decoration: none;
  color: inherit;
  font-weight: 300 !important;
  text-shadow: 1px 1px 2px rgb(63, 62, 62);
  line-height: 30px;
}
</style>